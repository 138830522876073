





























































































































































import Vue from 'vue'
import EditItemsForm from '@/components/EditRequisitionItemsForm.vue'
import ItemsFeeAdd from '@/components/ItemsFeeAdd.vue'
import ItemFilters from '@/components/ItemFilters.vue'
import { mapState } from 'vuex'
import { ApiResponse, Project, RequisitionItem, Sale, Model, Contact, RelatedSaleItem } from '@/interfaces'
import { stockStatuses } from '@/formHelpers'
import ItemRow from '@/views/requisitionItems/ItemRow.vue'
import QuickNav from '@/components/QuickNav.vue'
import MultiRegroupModal from '@/components/MultiRegroupModal.vue'

interface DropdownAction {
  action: string
  item?: RequisitionItem
  index?: number
  renumberBy?: string
}

interface Parameter {
  tab?: string
  search?: string
  page?: string
  orderby?: string
  order?: string
  rubriques?: string[]
  stockStatuses?: string[]
  entryDate?: string[]
  itemNumber?: string
  stockId?: string
  sale?: number
  requisition?: number
  dateOut?: string
  sort?: string
}

export default Vue.extend({
  components: { EditItemsForm, ItemsFeeAdd, ItemRow, ItemFilters, QuickNav, MultiRegroupModal },
  props: {
    project: {
      type: Object as () => Project,
      required: false,
      default: {
        requisition: {
          id: null,
        },
      },
    },
  },
  data() {
    return {
      addons: 0,
      busy: true,
      items: [] as RequisitionItem[],
      filteredItems: [] as RequisitionItem[],
      itemIds: [] as number[],
      itemsChecked: 0,
      checkAll: false,
      indeterminate: false,
      showTransferModal: false,
      projects: [] as Project[],
      itemsToEdit: [] as number[],
      itemToEditPrice: {},
      showEditModal: false,
      chosenModel: null as number | null,
      showDocumentModal: false,
      models: [] as Model[],
      transferTo: null,
      saveTrigger: false,
      docIndex: null as number | null,
      stockStatuses,
      showFeeModal: false,
      lastCheckedIndex: -1,
      checkAction: '',
      requisitionSales: [] as Sale[],
      isEditPrice: false,
      requisitionId: null as number | null,
      query: null as Parameter | null,
      contacts: [] as Contact[],
      selectedContactId: '',
      showMultiRegroupModal: false,
      selectedItemId: null as number | null,
      relatedSaleItems: [] as RelatedSaleItem[],
    }
  },
  computed: {
    ...mapState(['user', 'scrollPosition']),
  },
  watch: {
    user(newVal) {
      this.addons = newVal.client?.addons ?? 0
    },
    project(newVal) {
      // Load items after loading sales in the case where saleId exists in query
      this.fetchRequisitionSales(newVal.requisition?.id)
      this.requisitionId = newVal.requisition?.id
      if (newVal.id) {
        this.getTemplates()
      }
    },
    filteredItems: {
      deep: true,
      immediate: true,
      handler() {
        this.indeterminate = false
        this.checkAll = false
        if (this.itemsChecked === this.filteredItems.length) {
          this.checkAll = true
          this.resetEditForm()
        } else {
          if (this.itemsChecked > 0) {
            this.indeterminate = true
            this.resetEditForm()
          }
        }
      },
    },
    showEditModal(newVal) {
      if (newVal === false) {
        this.resetEditForm()
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("list mounted")

    if (this.project.requisition?.id) {
      // Load items after loading sales in the case where saleId exists in query
      this.fetchRequisitionSales(this.project.requisition?.id)
      this.requisitionId = this.project.requisition?.id
      this.getTemplates()
    }

    // Back Feature : On check le param dans l'URL
    // On doit le faire ici car pas de mise à jour du query de la route si on utilise history.pushState
    // Initialisation
    this.query = {
      ...this.query,
    }
    if (this.$route.query.sale) {
      this.query.sale = parseInt(this.$route.query.sale as string)
    }
    if (this.$route.query.itemNumber) {
      this.query.itemNumber = this.$route.query.itemNumber as string
    }
    if (this.$route.query.search) {
      this.query.search = this.$route.query.search as string
    }
    if (this.$route.query.stockId) {
      this.query.stockId = this.$route.query.stockId as string
    }
    if (this.$route.query.stockStatuses) {
      let filteredStockStatuses = []
      if (typeof this.$route.query.stockStatuses === 'string') {
        filteredStockStatuses.push(this.$route.query.stockStatuses)
      } else {
        const queryStockStatuses: string[] = this.$route.query.stockStatuses as string[]
        queryStockStatuses.forEach((status: string) => {
          filteredStockStatuses.push(status)
        })
      }
      this.query.stockStatuses = filteredStockStatuses
    }
    if (this.$route.query.rubriques) {
      let filteredRubriques = []
      if (typeof this.$route.query.rubriques === 'string') {
        filteredRubriques.push(this.$route.query.rubriques)
      } else {
        const queryRubriques: string[] = this.$route.query.rubriques as string[]
        queryRubriques.forEach((rubrique: string) => {
          filteredRubriques.push(rubrique)
        })
      }
      this.query.rubriques = filteredRubriques
    }
    if (this.$route.query.dateOut) {
      this.query.dateOut = this.$route.query.dateOut as string
    }
    if (this.$route.query.sort) {
      this.query.sort = this.$route.query.sort as string
    }
    if (this.user) {
      this.addons = this.user.client?.addons ?? 0
    }
  },
  methods: {
    handleItemCommand(command: DropdownAction) {
      // Mass edit
      if (command.action == 'massEdit') {
        this.editRequisitionItems()
      }

      // Mass edit
      if (command.action == 'massDelete') {
        this.deleteRequisitionItems()
      }

      // Transfer
      if (command.action == 'massTransfer') {
        this.showTransferModal = true
      }

      // Add fee
      if (command.action == 'add_fee') {
        let items = [] as number[]
        this.itemsToEdit = []

        this.filteredItems.forEach((item) => {
          if (item.isChecked === true) {
            items.push(item.id ?? 0)
          }
        })

        this.itemsToEdit = items
        this.showFeeModal = true
      }

      // Generate document
      if (command.action == 'document') {
        this.docIndex = command.index ?? null
        this.showDocumentModal = true
      }

      // Remove fees
      if (command.action == 'remove_all_seller_fees') {
        this.removeFeeFromRequisitionItems('seller')
      }

      if (command.action == 'remove_all_buyer_fees') {
        this.removeFeeFromRequisitionItems('buyer')
      }

      if (command.action == 'remove_all_medias') {
        this.removeMediaFromRequisitionItems()
      }

      if (command.action == 'under_construction') {
        this.$alert('Fonctionnalité en cours de développement.', 'Info', { confirmButtonText: 'OK' })
      }
      // Download photos
      if (command.action == 'MassDownloadImages') {
        this.massDownloadImages()
      }
    },
    massDownloadImages() {
      if (!this.busy) {
        this.busy = true

        //let success = false
        this.$api
          .post(`/file/mass-download-images/requisition`, {
            ids: this.itemsToEdit,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            if (apiResponse.success === true) {
              const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
              window.open(documentUrl, '_blank')
            }
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    fetchRequisitionItems(id: number | null) {
      if (id) {
        this.busy = true
        const loading = this.$loading({
          target: '#listContainer',
          text: 'Chargement des données...',
        })
        this.$api
          .get(`/requisition/${id}/items`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.items.splice(0)
            this.items = apiResponse.data

            this.setItemsInfo()

            this.filteredItems = this.items
            this.refreshItemList()
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.busy = false
            loading.close()

            // Automatically scroll to stored scroll position
            window.setTimeout(() => {
              window.scrollTo({ top: parseInt(this.scrollPosition) })
            }, 400)
          })
      }
    },
    fetchRequisitionSales(id: number | null) {
      if (id) {
        this.busy = true
        const loading = this.$loading({
          target: '#listContainer',
          text: 'Chargement des données...',
        })
        this.$api
          .get(`/requisition/${id}/sales`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.requisitionSales = apiResponse.data
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.busy = false
            loading.close()
            this.fetchRequisitionItems(id)
          })
      }
    },
    setItemsInfo() {
      this.items.forEach((item: RequisitionItem) => {
        if (item.id) {
          this.$set(item, 'isChecked', false)
          this.$set(
            item,
            'stockStatusColor',
            this.stockStatuses.find((stockStatus) => stockStatus.value === item.stockStatus)?.color ?? '#EFE6FD'
          )
          this.$set(
            item,
            'stockStatusLabel',
            this.stockStatuses.find((stockStatus) => stockStatus.value === item.stockStatus)?.label ?? '-'
          )
          this.$set(
            item,
            'stockTextColor',
            this.stockStatuses.find((stockStatus) => stockStatus.value === item.stockStatus)?.textColor ?? '-'
          )
        }
      })
    },
    duplicateItem(item: RequisitionItem) {
      if (item) {
        this.$confirm('Êtes-vous sûr(e) de vouloir dupliquer ce lot ?', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }).then(() => {
          this.$api
            .post(`/requisition/${this.project.id}/item/${item.id}/duplicate`)
            .then(() => {
              this.fetchRequisitionItems(this.project.requisition?.id ?? null)
            })
            .catch(() => {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
              })
            })
        })
      }
    },
    deleteRequisitionItem(item: RequisitionItem) {
      if (item) {
        this.$confirm(
          'Êtes-vous sûr(e) de vouloir supprimer ce lot ? Attention, cette opération est irréversible.',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        ).then(() => {
          this.$api
            .delete(`/requisition/${this.project.id}/item/${item.id}`)
            .then(() => {
              this.toggleCheckAll(false)
              this.$emit('requisitionItemDeleted')
            })
            .catch(() => {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
              })
            })
        })
      }
    },
    deleteRequisitionItems() {
      let items = [] as number[]

      this.filteredItems.forEach((item) => {
        if (item.isChecked === true) {
          items.push(item.id ?? 0)
        }
      })

      if (items.length > 0) {
        this.$confirm(
          'ATTENTION ! Êtes-vous sûr(e) de vouloir SUPPRIMER ce(s) lot(s) ? CETTE OPERATION EST IRREVERSIBLE.',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        ).then(() => {
          this.$api
            .delete(`/requisition/${this.project.id}/items`, {
              data: {
                items,
              },
            })
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              this.items = apiResponse.data
              this.setItemsInfo()
              this.filteredItems = apiResponse.data
              this.refreshItemList()

              this.toggleCheckAll(false)
              this.$emit('requisitionItemDeleted')
            })
        })
      }
    },
    removeFeeFromRequisitionItems(type: string) {
      if (this.itemsToEdit.length > 0) {
        let message = 'Êtes-vous sûr(e) de vouloir supprimer tous les frais '

        switch (type) {
          case 'seller':
            message += 'vendeur '
            break

          case 'buyer':
            message += 'acheteur '
            break
        }

        if (this.itemsToEdit.length > 1) {
          message += 'des lots sélectionnés ?'
        } else {
          message += 'du lot sélectionné ?'
        }
        message += ' Attention, cette opération est irréversible.'

        this.$confirm(`${message}`, 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }).then(() => {
          this.$api
            .delete(`/fee/requisition/${this.project.requisition?.id}/items/${type}`, {
              data: {
                items: this.itemsToEdit,
              },
            })
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              const countItems = apiResponse.data.count
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: `Les frais ont été supprimés sur ${countItems} lot(s) !`,
              })
              this.updateItems(apiResponse.data.items)
              this.toggleCheckAll(false)
            })
        })
      }
    },
    removeMediaFromRequisitionItems() {
      if (this.itemsToEdit.length > 150) {
        this.$notify({
          type: 'warning',
          title: 'Action annulée pour des raisons techniques',
          message: 'Veuillez supprimer les photos par tranche de 150 lots',
        })
      } else {
        if (this.itemsToEdit.length > 0) {
          let message = 'ATTENTION !!! Êtes-vous sûr(e) de vouloir supprimer TOUTES LES PHOTOS '

          if (this.itemsToEdit.length > 1) {
            message += 'des lots sélectionnés ?'
          } else {
            message += 'du lot sélectionné ?'
          }
          message += ' CETTE OPERATION EST IRREVERSIBLE.'

          this.$confirm(`${message}`, 'Confirmation', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }).then(() => {
            this.$api
              .delete(`/file/requisition/${this.project.requisition?.id}/items`, {
                data: {
                  items: this.itemsToEdit,
                },
              })
              .then((response) => {
                const apiResponse = response.data as ApiResponse

                const countItems = apiResponse.data.count
                this.$notify({
                  type: 'success',
                  title: 'Succès',
                  message: `Les photos ont été supprimés sur ${countItems} lot(s) !`,
                })
                this.updateItems(apiResponse.data.items)
                this.toggleCheckAll(false)
              })
          })
        }
      }
    },
    detachItemFromSale(item: RequisitionItem) {
      this.$confirm(
        'Êtes-vous sûr(e) de retirer ce lot de cette vente ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .post(`/sale/${item.sale?.id}/item/${item.id}/detach`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            // This event will trigger the refresh of the whole project
            this.$emit('saleItemDetached')
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
          .finally(() => {
            this.toggleCheckAll(false)
          })
      })
    },
    toggleCheckAll(status: boolean, updateCheckboxValue = true) {
      this.indeterminate = false
      if (updateCheckboxValue) {
        this.filteredItems.forEach((item) => {
          this.$set(item, 'isChecked', status)
        })
      }

      if (status) {
        this.itemsChecked = this.filteredItems.length
        this.filteredItems.forEach((item: RequisitionItem) => {
          const itemId = item.id ?? -1
          if (itemId > -1) {
            this.itemsToEdit.push(itemId)
          }
        })
      } else {
        this.itemsChecked = 0
        this.itemsToEdit = []
        this.lastCheckedIndex = -1
        this.checkAction = ''
      }
    },
    toggleCheck(item: RequisitionItem, index: number, event: PointerEvent | null) {
      const itemId = item.id ?? -1
      const itemToEditIndex = this.itemsToEdit.findIndex((itemToEditId) => {
        return itemToEditId === itemId
      })
      this.checkAction = itemToEditIndex === -1 ? 'select' : 'deselect'

      if (event?.shiftKey && this.lastCheckedIndex !== -1 && this.checkAction === 'select') {
        let start = this.lastCheckedIndex
        let end = index - 1
        // can select top to bottom or bottom to top
        // always start with lowest value
        if (start > end) {
          start = index + 1
          end = this.lastCheckedIndex
        }

        for (let i = start; i <= end; i++) {
          const currentItem = this.filteredItems[i] ?? null
          const currentItemId = currentItem?.id ?? -1
          if (currentItem) {
            this.markSelectDeselect(currentItemId)
            currentItem.isChecked = true
          }
        }
        window?.getSelection()?.removeAllRanges()
      }

      this.markSelectDeselect(itemId)
      this.lastCheckedIndex = index
      item.isChecked = this.checkAction === 'select'

      if (this.itemsToEdit.length === 0) {
        // Reset multiple selection data
        this.lastCheckedIndex = -1
        this.checkAction = ''
      }
    },
    markSelectDeselect(itemId: number) {
      const currentPos = this.itemsToEdit.findIndex((itemToEditId) => itemToEditId === itemId) ?? -1

      if (this.checkAction === 'select' && currentPos === -1) {
        this.itemsToEdit.push(itemId)
        this.itemsChecked++
      } else if (this.checkAction === 'deselect' && currentPos !== -1) {
        this.itemsToEdit.splice(currentPos, 1)
        if (this.itemsChecked > 0) {
          this.itemsChecked--
        }
      }
    },
    editRequisitionItem(item: RequisitionItem) {
      this.$router.push({
        name: 'requisition_item_edit',
        params: {
          itemId: item.id?.toString() ?? '',
        },
        query: {
          backTo: this.$route.name,
          backScrollTo: window.scrollY.toString(),
        },
      })
    },
    createRequisitionItem() {
      const requisitionId = this.$route.params.requisitionId?.toString() ?? ''

      const loading = this.$loading({
        target: '.items-container',
        text: 'Création du lot...',
      })

      this.$api
        .post(`/requisition/${requisitionId}/newitem`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$router.push({
            name: 'requisition_item_edit',
            params: { itemId: apiResponse.data.id.toString() },
          })
        })
        .finally(() => {
          loading.close()
        })
    },
    searchRequisitions(query: string): Project[] {
      if (query.length >= 3) {
        this.projects = [] as Project[]
        this.$api
          .get(`/requisitions`, {
            params: {
              search: query,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            apiResponse.data.forEach((project: Project) => {
              this.projects.push(project)
            })
          })
      }

      return this.projects
    },
    transferRequisition() {
      if (this.transferTo !== null) {
        let items = [] as number[]

        this.filteredItems.forEach((item) => {
          if (item.isChecked === true) {
            items.push(item.id ?? 0)
          }
        })

        this.$api
          .post(`/requisition/${this.project.requisition?.id}/items/transfer`, {
            destination: this.transferTo,
            items,
          })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.toggleCheckAll(false)
            this.$emit('requisitionItemsTransfered')
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.showTransferModal = false
            this.transferTo = null
            this.projects = []
          })
      }
    },
    handleClose(done: CallableFunction) {
      this.saveTrigger = !this.saveTrigger
      done()
    },
    editRequisitionItems() {
      let items = [] as number[]
      this.itemsToEdit = []

      this.filteredItems.forEach((item) => {
        if (item.isChecked === true) {
          items.push(item.id ?? 0)
        }
      })

      this.itemsToEdit = items

      if (items.length > 0) {
        this.showEditModal = true
      }
    },
    generateItemsDocument() {
      const model = this.models.find((model) => model.id === this.chosenModel)
      const type = model?.type ?? null
      if (!type) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Veuillez choisir un type de document à générer',
        })
        return
      }
      let documentType = 'Le document'
      if (model) {
        const templateName = model.name?.toLowerCase() ?? null
        documentType = templateName ? `Le ${templateName}` : 'Le document'
      }

      const items = [] as number[]
      if (this.docIndex !== null) {
        items.push(this.itemIds[this.docIndex])
      } else {
        this.filteredItems.forEach((item, key) => {
          if (this.docIndex && key === this.docIndex) {
            items.push(this.itemIds[key])
          } else if (!this.docIndex && item.isChecked === true) {
            items.push(this.itemIds[key])
          }
        })
      }

      this.busy = true
      this.$api
        .post(`/document/requisition/${this.project?.requisition?.id}/${type}`, {
          itemsIds: items,
          chosenModel: this.chosenModel,
          selectedContactId: this.selectedContactId,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          const size = apiResponse.data.size
          const time = apiResponse.data.time

          this.$emit('updateDocuments', apiResponse.data.documents)

          this.fetchRequisitionItems(this.project.requisition?.id ?? null)

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: `${documentType} a été créé avec succès en ${time} secondes et ${size} Mo ! Il est disponible dans l'onglet Documents. L'état du stock et la date de sortie du lot ont été mis à jour.`,
          })
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          this.closeGenerateDocumentModal()
          this.toggleCheckAll(false)
        })
    },
    updateItemSignedDocument(items: RequisitionItem[]) {
      this.items.forEach((item: RequisitionItem, index: number) => {
        const updatedItemDoc = items.find((updatedItem) => updatedItem.id === item.id) ?? null
        if (updatedItemDoc) {
          this.items[index].salesMandate = updatedItemDoc.salesMandate
        }
      })
      this.filteredItems = this.items
    },
    updateItems(items: RequisitionItem[]) {
      this.items = items.reduce((accumulator: RequisitionItem[], updatedItem: RequisitionItem) => {
        let itemToUpdateIndex = accumulator.findIndex((item: RequisitionItem) => item.id === updatedItem.id)
        if (itemToUpdateIndex > -1) {
          const itemData = {
            ...updatedItem,
            stockStatusColor:
              this.stockStatuses.find((stockStatus) => stockStatus.value === updatedItem.stockStatus)?.color ?? '-',
            stockTextColor:
              this.stockStatuses.find((stockStatus) => stockStatus.value === updatedItem.stockStatus)?.textColor ??
              '-',
            stockStatusLabel:
              this.stockStatuses.find((stockStatus) => stockStatus.value === updatedItem.stockStatus)?.label ?? '-',
            isChecked: false,
          }
          accumulator.splice(itemToUpdateIndex, 1, itemData)
        }
        return accumulator
      }, this.items)
      this.filteredItems = this.items
      this.refreshItemList()
    },
    closeModal(updateCheckboxValue = true) {
      this.toggleCheckAll(false, updateCheckboxValue)
      this.showEditModal = false
      this.showFeeModal = false
      this.resetEditForm()
    },
    resetEditForm() {
      this.isEditPrice = false
      this.itemToEditPrice = {}
    },
    closeGenerateDocumentModal() {
      this.docIndex = null
      this.showDocumentModal = false
      this.chosenModel = null
    },
    getTemplates() {
      this.$api.get(`/model/requisition/list`).then((response) => {
        const apiResponse = response.data as ApiResponse

        this.models = apiResponse.data
      })
    },
    getProjectTitle(project: Project): string {
      return `${project.generatedNumber ?? '-'} - ${project.requisition?.debiteur?.formName ?? '-'}`
    },
    getSaleTitle(sale: Sale): string {
      let saleTitle = ''
      if (sale?.title) {
        saleTitle += ' ' + sale?.title
      }
      if (sale?.startDate) {
        const date = Date.parse(sale?.startDate)
        if (date) {
          saleTitle += ' - ' + new Intl.DateTimeFormat('fr-FR').format(date)
        }
      }
      return saleTitle !== '' ? saleTitle : '-'
    },
    actionDocument(index: number | null) {
      this.docIndex = index
      this.showDocumentModal = true
    },
    editPrices(itemToEdit: RequisitionItem) {
      this.itemToEditPrice = {}
      if (itemToEdit.id) {
        this.itemsToEdit = []
        this.itemToEditPrice = {
          id: itemToEdit.id,
          itemNumber: itemToEdit.itemNumber,
          itemSubNumber: itemToEdit.itemSubNumber,
          estimationLow: itemToEdit.estimationLow,
          estimationHigh: itemToEdit.estimationHigh,
          description: itemToEdit.description,
          reservePrice: itemToEdit.reservePrice,
          reserveNetPrice: itemToEdit.reserveNetPrice,
          startingPrice: itemToEdit.startingPrice,
        }
        this.isEditPrice = true
        this.showEditModal = true
      } else {
        this.isEditPrice = false
        this.showEditModal = true
      }
    },
    regroup(selectedItem: RequisitionItem) {
      this.selectedItemId = selectedItem?.id ?? null

      const relatedSaleItems = [] as RelatedSaleItem[]
      this.items.forEach((item: RequisitionItem) => {
        if (item.id != this.selectedItemId) {
          let itemNumber = item.stockId
          const relatedSaleItem = {
            id: item.id,
            itemNumber,
            requisitionId: item?.requisition?.id,
            description: item.description,
          } as RelatedSaleItem
          relatedSaleItems.push(relatedSaleItem)
        }
      })
      this.relatedSaleItems = relatedSaleItems

      this.triggerShowMultiRegroupModal(true)
    },
    triggerShowMultiRegroupModal(value: boolean) {
      this.showMultiRegroupModal = value
    },
    updateAfterRegroup() {
      this.fetchRequisitionItems(this.project.requisition?.id ?? null)
    },
    setQuery(query: Parameter) {
      this.query = query
    },
    refreshItemList() {
      let filtered = false
      this.itemsChecked = 0
      this.toggleCheckAll(false)

      this.filteredItems = Object.assign([], this.items) // copy because sort change l'ordre de l'objet d'origine (de this.item)

      if (this.query?.search && this.query?.search !== '') {
        this.filteredItems = this.filteredItems.filter((item: RequisitionItem) =>
          item.description?.toLowerCase().includes(this.query?.search?.toLowerCase() ?? '') ||
          item.refExterne?.toLowerCase().includes(this.query?.search?.toLowerCase() ?? '') 
        )
        filtered = true
      }

      if (this.query?.rubriques && this.query?.rubriques.length > 0) {
        const rubriquesList = this.query.rubriques
        this.filteredItems = this.filteredItems.filter((item: RequisitionItem) =>
          rubriquesList.includes(item.rubriqueKey ?? '')
        )
        filtered = true
      }

      if (this.query?.stockStatuses && this.query?.stockStatuses.length > 0) {
        const stockStatusList = this.query.stockStatuses
        this.filteredItems = this.filteredItems.filter((item: RequisitionItem) =>
          stockStatusList.includes(item.stockStatus ?? '')
        )
        filtered = true
      }

      if (this.query?.sale) {
        this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => item.sale?.id == this.query?.sale)
        filtered = true
      }

      if (this.query?.itemNumber) {
        this.filteredItems = this.filteredItems.filter(
          (item: RequisitionItem) => item.fullItemNumber == this.query?.itemNumber
        )
        filtered = true
      }

      if (this.query?.stockId) {
        this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => item.stockId == this.query?.stockId)
        filtered = true
      }

      if (this.query?.dateOut !== 'true') {
        if (filtered) {
          this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => item.dateOut === null)
        } else {
          this.filteredItems = this.items.filter((item: RequisitionItem) => {
            return item.dateOut === null
          })
        }
      }
      this.setFilteredItemIds()

      if (this.query?.sort) {
        this.sortItems()
        filtered = true
      }      
    },
    sortItems() {
      switch (this.query?.sort) {
        case 'estimationLowAsc':
          this.filteredItems = this.filteredItems.sort(this.sortByEstimationLowAsc)
          break
        case 'endPriceAsc':
          this.filteredItems = this.filteredItems.sort(this.sortByEndPriceAsc)
          break
        case 'reservePriceAsc':
          this.filteredItems = this.filteredItems.sort(this.sortByReservePriceAsc)
          break
        case 'estimationLowDesc':
          this.filteredItems = this.filteredItems.sort(this.sortByEstimationLowDesc)
          break
        case 'endPriceDesc':
          this.filteredItems = this.filteredItems.sort(this.sortByEndPriceDesc)
          break
        case 'reservePriceDesc':
          this.filteredItems = this.filteredItems.sort(this.sortByReservePriceDesc)
          break          
      }        
    },
    sortByEstimationLowAsc(requisitionItem1: RequisitionItem, requisitionItem2: RequisitionItem) {
      const value1 = parseInt(requisitionItem1.estimationLow ?? '0')
      const value2 = parseInt(requisitionItem2.estimationLow ?? '0')   
      if (value1 > value2) {
        return 1
      }
      if (value1 < value2) {
        return -1
      }
      return 0
    },
    sortByEstimationLowDesc(requisitionItem1: RequisitionItem, requisitionItem2: RequisitionItem) {
      const value1 = parseInt(requisitionItem1.estimationLow ?? '0')
      const value2 = parseInt(requisitionItem2.estimationLow ?? '0')   
      if (value1 > value2) {
        return -1
      }
      if (value1 < value2) {
        return 1
      }
      return 0
    },    
    sortByEndPriceAsc(requisitionItem1: RequisitionItem, requisitionItem2: RequisitionItem) {
      const value1 = parseInt(requisitionItem1.endPrice ?? '0')
      const value2 = parseInt(requisitionItem2.endPrice ?? '0')   
      if (value1 > value2) {
        return 1
      }
      if (value1 < value2) {
        return -1
      }
      return 0
    },
    sortByEndPriceDesc(requisitionItem1: RequisitionItem, requisitionItem2: RequisitionItem) {
      const value1 = parseInt(requisitionItem1.endPrice ?? '0')
      const value2 = parseInt(requisitionItem2.endPrice ?? '0')   
      if (value1 > value2) {
        return -1
      }
      if (value1 < value2) {
        return 1
      }
      return 0
    },    
    sortByReservePriceAsc(requisitionItem1: RequisitionItem, requisitionItem2: RequisitionItem) {
      const value1 = parseInt(requisitionItem1.reservePrice ?? '0')
      const value2 = parseInt(requisitionItem2.reservePrice ?? '0')   
      if (value1 > value2) {
        return 1
      }
      if (value1 < value2) {
        return -1
      }
      return 0
    },
    sortByReservePriceDesc(requisitionItem1: RequisitionItem, requisitionItem2: RequisitionItem) {
      const value1 = parseInt(requisitionItem1.reservePrice ?? '0')
      const value2 = parseInt(requisitionItem2.reservePrice ?? '0')   
      if (value1 > value2) {
        return -1
      }
      if (value1 < value2) {
        return 1
      }
      return 0
    },    
    setFilteredItemIds() {
      this.itemIds = this.filteredItems.map((item: RequisitionItem) => item.id) as number[]
    },
    loadContacts(isVisible: boolean) {
      if (isVisible) {
        this.contacts = []

        // TOTO : appel API liste des contact de la personne concernée de l'inventaire
        // this.requisitionId => Id de la requisition
        // this.selectedContactId => contact sélectionné

        this.$api.get('/requisition/' + this.requisitionId + '/contacts').then((response) => {
          const apiResponse = response.data as ApiResponse

          apiResponse.data.forEach((contact: Contact) => {
            this.contacts.push(contact)
          })
        })
      }
    },
    adjudicationTotal() {
      let total = 0

      this.filteredItems.forEach((item: RequisitionItem) => {
        if (
          item.stockStatus == 'sold' ||
          item.stockStatus == 'amicably_sold' ||
          item.stockStatus == 'unfulfilled' ||
          item.stockStatus == 'to_buyer_back'
        )
          total = total + parseInt(item.endPrice ?? '0')
      })

      //this.totalMoney = new Intl.NumberFormat('fr-Fr', { style: 'currency', currency: 'EUR' }).format(total)
      return new Intl.NumberFormat('fr-Fr', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(total)
    },
    estimationTotal() {
      let estimationLow = 0
      let estimationHigh = 0

      this.filteredItems.forEach((item: RequisitionItem) => {
        estimationLow = estimationLow + parseInt(item.estimationLow ?? '0')
        estimationHigh = estimationHigh + parseInt(item.estimationHigh ?? '0')
      })

      const estimationLowMoney = new Intl.NumberFormat('fr-Fr', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(estimationLow)
      const estimationHighMoney = new Intl.NumberFormat('fr-Fr', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(estimationHigh)

      return `${estimationLowMoney} - ${estimationHighMoney}`
    },
  },
})
